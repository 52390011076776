import React from "react";

interface IFirstLoadContextData {
  firstLoad: boolean;
  setFirstLoad?: any;
}
const FirstLoadContext = React.createContext<IFirstLoadContextData>({
  firstLoad: false,
});

export default FirstLoadContext;
