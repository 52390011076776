import React, { FC, useRef } from "react";
import {
  HeaderContainer,
  HeaderLogo,
  HeaderBanner,
  HeaderLogoItem,
  SocialIcons,
  HeaderNavigation,
  BurgerBtn,
  HeaderNavigationLink,
} from "./index.styled";
import logoSmall from "../../images/logo-PH-small.svg";
import { IHeaderProps } from "../../interfaces/header-props";
const pagesData = [
  {
    name: "Residences",
    link: "/",
  },
  {
    name: "Penthouses",
    link: "/penthouses",
  },
  {
    name: "Amenities",
    link: "/amenities",
  },
  {
    name: "Neighborhood",
    link: "/neighborhood",
  },
  {
    name: "Floor Plans",
    link: "/floor-plans",
  },
  {
    name: "Team",
    link: "/team",
  },
];

const Header: FC<IHeaderProps> = ({ location }) => {
  const headerNavigationRef = useRef<HTMLDivElement>(null);
  const burgerBtnRef = useRef<HTMLDivElement>(null);
  const showMenu = () => {
    headerNavigationRef?.current?.classList.toggle("active");
    burgerBtnRef?.current?.classList.toggle("active");
  };
  return (
    <HeaderContainer id="headerWrapper">
      <HeaderLogo href={"/"}>
        <HeaderLogoItem src={logoSmall} />
      </HeaderLogo>
      <HeaderBanner>
        <span>30 LIMITED EDITION COASTAL HOMES</span> <br />
        Residences starting from the $2 Millions.
      </HeaderBanner>
      <BurgerBtn onClick={showMenu} ref={burgerBtnRef}>
        <span />
      </BurgerBtn>
      <HeaderNavigation ref={headerNavigationRef}>
        {pagesData.map((el, key) => (
          <HeaderNavigationLink
            onClick={showMenu}
            to={el.link}
            key={key}
            className={
              location.pathname === el.link ||
                location.pathname === el.link + "/"
                ? "active"
                : ""
            }
          >
            {el.name}
          </HeaderNavigationLink>
        ))}

        <a className={"contact-btn"} href={"/contact"}>Contact</a>
        <SocialIcons>
          <a href="https://www.facebook.com/ParkhouseNewport/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <path className="a"
                d="M739.373-1764.481a3.249,3.249,0,0,1,2.384.99,3.251,3.251,0,0,1,.991,2.385v11.25a3.251,3.251,0,0,1-.991,2.385,3.25,3.25,0,0,1-2.384.99h-2.2v-6.973H739.5l.351-2.719H737.17v-1.734a1.5,1.5,0,0,1,.275-.984,1.366,1.366,0,0,1,1.072-.328l1.43-.012v-2.427a15.771,15.771,0,0,0-2.086-.105,3.478,3.478,0,0,0-2.549.938,3.54,3.54,0,0,0-.955,2.648v2h-2.344v2.719h2.344v6.973h-6.234a3.252,3.252,0,0,1-2.385-.99,3.25,3.25,0,0,1-.99-2.385v-11.25a3.25,3.25,0,0,1,.99-2.385,3.252,3.252,0,0,1,2.385-.99Z"
                transform="translate(-724.748 1764.481)" />
            </svg>
          </a>
          <a href="https://instagram.com/parkhousenewport?utm_medium=copy_link" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.999" viewBox="0 0 18 17.999">
              <path className="a"
                d="M1535.4-3539.765a1.125,1.125,0,0,1,1.125,1.125,1.126,1.126,0,0,1-1.125,1.125,1.125,1.125,0,0,1-1.125-1.125A1.124,1.124,0,0,1,1535.4-3539.765Zm-4.875,2.906a2.962,2.962,0,0,0-3,3,2.962,2.962,0,0,0,3,3,2.962,2.962,0,0,0,3-3A2.961,2.961,0,0,0,1530.527-3536.86Zm0-1.594a4.542,4.542,0,0,1,4.594,4.594,4.6,4.6,0,0,1-4.594,4.595,4.6,4.6,0,0,1-4.594-4.595A4.6,4.6,0,0,1,1530.527-3538.453Zm.713-2.812h-1.446a25.878,25.878,0,0,0-2.923.094,4.341,4.341,0,0,0-1.594.282,2.963,2.963,0,0,0-1.125.75,3.893,3.893,0,0,0-.656,1.031,4.352,4.352,0,0,0-.281,1.594,24.072,24.072,0,0,0-.094,2.944v.711c0,2.438.094,2.719.094,3.657a5.738,5.738,0,0,0,.281,1.781,3.876,3.876,0,0,0,.656,1.031,3.833,3.833,0,0,0,1.031.656,5.557,5.557,0,0,0,1.688.282,20.972,20.972,0,0,0,2.541.092h1.369c2.206-.006,2.5-.093,3.4-.093a4.534,4.534,0,0,0,1.688-.282,3.815,3.815,0,0,0,1.031-.656,3.857,3.857,0,0,0,.657-1.031,5.563,5.563,0,0,0,.281-1.687,22.449,22.449,0,0,0,.094-2.735v-1.174c-.006-2.205-.094-2.5-.094-3.4a4.543,4.543,0,0,0-.281-1.687,2.007,2.007,0,0,0-.657-1.125,3.85,3.85,0,0,0-1.031-.656,5.559,5.559,0,0,0-1.688-.282A24.179,24.179,0,0,0,1531.24-3541.266Zm.446-1.593a22.1,22.1,0,0,1,2.592.093,6.384,6.384,0,0,1,2.156.375,4.224,4.224,0,0,1,1.593,1.031,4.231,4.231,0,0,1,1.031,1.594,6.351,6.351,0,0,1,.375,2.156,24.708,24.708,0,0,1,.094,2.8v1.213c-.006,2.2-.094,2.585-.094,3.488a6.356,6.356,0,0,1-.375,2.157,4.237,4.237,0,0,1-1.031,1.594,4.232,4.232,0,0,1-1.593,1.031,6.39,6.39,0,0,1-2.156.375,25.137,25.137,0,0,1-2.8.092h-1.213c-2.2-.006-2.585-.092-3.488-.092a6.385,6.385,0,0,1-2.156-.375,4.242,4.242,0,0,1-1.594-1.031,4.247,4.247,0,0,1-1.031-1.594,6.383,6.383,0,0,1-.374-2.157,23.227,23.227,0,0,1-.094-2.6v-2.448a19.374,19.374,0,0,1,.093-2.359,6.54,6.54,0,0,1,.374-2.25,7.649,7.649,0,0,1,1.031-1.594,6.289,6.289,0,0,1,1.688-1.031,6.384,6.384,0,0,1,2.156-.375,19.8,19.8,0,0,1,2.359-.093Z"
                transform="translate(-1521.527 3542.859)" />
            </svg>
          </a>
        </SocialIcons>
      </HeaderNavigation>

    </HeaderContainer>
  );
};

export default Header;
