import styled from "styled-components";
import { Link } from "gatsby";
import { mediaMax } from "../../helpers/MediaQueries";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;
  padding: 0;
  height: 114px;
  background: #f5f3f3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  ${mediaMax.tabletLandscape`  
    align-items: center;
    padding: 0 32px 0 0;
    height: 80px;
    flex-wrap: no-wrap;
  `}
  ${mediaMax.phoneXXL`  
    padding: 0 32px 12px 0;
  `}
`;
export const HeaderBanner = styled.h2`
  font: normal normal 100 20px/24px Optima;
  margin: 0 auto;
  padding-right: 110px;
  text-align: center;
  span {
    letter-spacing: 0.15em;
  }
  ${mediaMax.tabletLandscape`  
    font: normal normal 100 13px/15px Optima;
    padding-right: 0;
  `}
  ${mediaMax.phoneXXL`  
    font: normal normal 100 10px/10px Optima;
    position: absolute;
    bottom: 0;
    padding: 2px 0;
    color: #F5F3F3;
    background: #9AA49A;
    width: 100%;
    span {
      padding-bottom: 1px;
      display: inline-block;
    }
  `}
  ${mediaMax.phoneLarge`
    font: normal normal 100 9px/10px Optima;
  `}
  ${mediaMax.phone`
    font: normal normal 100 8px/10px Optima;
  `}

`
export const HeaderLogo = styled.a`
  margin-left: 24px;
  width: 110px;
`;
export const HeaderLogoItem = styled.img`
  margin: 0;
  width: 100%;
`;
export const HeaderNavigation = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: 0.4s ease-out;
  padding-right: 84px;
  background-color: #9AA49A;
  .contact-btn {
    margin-right: 0;
    padding: 2px 15px 0;
    height: 32px;
    display: inline-flex;
    align-items: center;
    text-align: left;
    font: normal normal 100 14px/17px Optima;
    letter-spacing: 2.1px;
    background: #889287;
    color: #f5f3f3;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.25s ease-out;
    position: relative;
    &.active:after {
      background: transparent;
    }
    &:hover {
      background: #88a585;
      box-shadow: 1px 1px 4px #a9c4a5;
    }
  }
  ${mediaMax.tabletLandscape`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    min-height: 100vh;
    max-width: 384px;
    width: 100%;
    background: #535D53;
    a {
      font: normal normal 100 18px/22px Optima;
      letter-spacing: 2.7px;
      color: #F5F3F3;
      padding: 16px 0;
      margin: 0;
     }
   .contact-btn {
      font: normal normal 100 18px/40px Optima;
      margin-top: 24px;
      background: transparent;
      border: 1px solid #F5F3F3;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
  `};
  ${mediaMax.phoneXL`
    max-width: 100%;
  `};
`;
export const SocialIcons = styled.div`
  display: inline;
  padding: 2px 0 0 4px;

  a {
    display: inline-block;
    margin-left: 12px;

    svg {
      width: 18px;
      height: 18px;
      fill: #fff;
      transition: .4s ease-out;
    }

    &:hover svg {
      opacity: .7;
    }
  }

  ${mediaMax.tabletLandscape`
    padding-top: 40px;
    a {
      margin: 0 12px;
      svg {
        fill: #fff;
        width: 24px;
        height: 24px;
      }
     }
  `};
`;
export const BurgerBtn = styled.div`
  display: none;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 0;
  cursor: pointer;
  ${mediaMax.tabletLandscape`
    display: inline-flex;
  `}
  span {
    display: flex;
    width: 32px;
    height: 1px;
    background-color: #535d53;
    position: relative;
    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: #535d53;
      position: absolute;
      left: 0;
      z-index: 1;
      transition: 0.4s ease-out;
    }
    &:before {
      top: -12px;
    }
    &:after {
      bottom: -12px;
    }
  }

  &.active {
    span {
      background: none;
      &:before {
        background-color: #f5f3f3;
        transform: rotate(45deg) translate(6px, 11px);
      }
      &:after {
        background-color: #f5f3f3;
        transform: rotate(-45deg) translate(6px, -11px);
      }
    }
  }
`;

export const HeaderNavigationLink = styled(Link)`
  margin-right: 24px;
  text-align: left;
  font: normal normal 100 14px/17px Optima;
  letter-spacing: 2.1px;
  color: #F5F3F3;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  ${mediaMax.desktopSmall`
    margin-right: 22px;
  `}
  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    width: 100%;
    background: transparent;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.5s;
  }
  &.active:after {
    background: #F5F3F3;
  }
`;
