import React, { FC, useEffect, useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "../header";
import BannerModal from "../modal";
import Transition from "../transition/index";
import FirstLoadContext from "../../context";
import { GlobalStyle } from "./index.styled";
import { ILayoutProps } from "../../interfaces/layout-props";
import { LayoutWrapper, PageWrapper } from "./index.styled";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
gsap.config({nullTargetWarn: false,});


const Layout: FC<ILayoutProps> = ({ children, location }) => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const { site } = useStaticQuery(query);

  const {
    title,
    titleTemplate,
    description
  } = site.siteMetadata;

  useEffect(() => {
    pageWrapperRef?.current?.classList.remove("loading");
    setTimeout(() => {
      setFirstLoad(false);
    }, 8000);
  });

  return (
    <PageWrapper className={"loading"} ref={pageWrapperRef}>
      <FirstLoadContext.Provider value={{ firstLoad, setFirstLoad }}>
        <Helmet title={title} titleTemplate={titleTemplate}>
          <meta name="description" content={description} />
          <link rel="canonical" href="https://parkhouseresidences.com/" />
        </Helmet>
        <GlobalStyle />
        <Header location={location} />
        <BannerModal location={location}/>
        <LayoutWrapper id="layoutWrapper">
          <Transition location={location} isFirstLoad={firstLoad}>
            {children}
          </Transition>
        </LayoutWrapper>
      </FirstLoadContext.Provider>
    </PageWrapper>
  );
};
export default Layout;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        titleTemplate
        description
      }
    }
  }
`