import styled, { createGlobalStyle } from "styled-components";
import OptimaFontBoldttf from "../../fonts/optima/Optima Medium.ttf";
import OptimaFontNormalttf from "../../fonts/optima/OPTIMA.ttf";
import OptimaFontBoldwoff from "../../fonts/optima-webfont/Optima Medium.woff";
import OptimaFontNormalwoff from "../../fonts/optima-webfont/OPTIMA.woff";
import OptimaFontLightttf from "../../fonts/optima/OptimaNovaLTProLight.ttf";
import OptimaFontLightotf from "../../fonts/optima/OptimaNovaLTProLight.otf";
import OptimaFontLightwoff from "../../fonts/optima/OptimaNovaLTProLight.woff";
import OptimaFontLightwoff2 from "../../fonts/optima/OptimaNovaLTProLight.woff2";

import { mediaMax } from "../../helpers/MediaQueries";

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: Optima, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    background: #F5F3F3;
  }
  body::-webkit-scrollbar {
    width: 5px;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  .tl-wrapper {
      position: unset;
  }
  .body-title {
    font: normal normal 100 24px/29px Optima;
    letter-spacing: 3.6px;
    color: #535D53;
    margin-bottom: 48px;
    text-transform: uppercase;
    ${mediaMax.tabletLandscape`
      margin-bottom: 24px;
    `};
  }

.body-copy-highlighted {
  font: normal normal 100 37px/45px Optima;
  letter-spacing: 0;
  &-heading {
    letter-spacing: 9.77px;
  }
  &-desc {
    font: 100 4vh / 6vh Optima;
    letter-spacing: 0;
  }
}

.body-copy {
  font: normal normal 100 17px/23px Optima;
  letter-spacing: 0.09px;
  color: #535D53;
}

.image-label {
  text-align: left;
  font: normal normal 100 12px/14px Optima;
  letter-spacing: 1.8px;
  color: #535D53;
  text-transform: uppercase;
  outline: none!important;
}
.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: .4s ease-out;
  z-index: 1;
  &:hover {
    opacity: .8;
  }
}
.mb-48 {
  margin-bottom: 48px;
}

@font-face {
  font-family: 'Optima';
  src: url(${OptimaFontLightttf}) format('ttf'),
  url(${OptimaFontLightotf}) format('otf'),
  url(${OptimaFontLightwoff}) format('woff'),
  url(${OptimaFontLightwoff2}) format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Optima';
  src: url(${OptimaFontNormalttf}) format('ttf'),
  url(${OptimaFontNormalwoff}) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Optima';
  src: url(${OptimaFontBoldttf}) format('ttf'),
  url(${OptimaFontBoldwoff}) format('woff');
  font-weight: bold;
  font-style: normal;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

b,
strong {
  font-weight: inherit;
}


img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: 700;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

*:before {
  box-sizing: inherit;
}

*:after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

h1 {
  padding: 0;
  margin: 0;
}

h2 {
  padding: 0;
  margin: 0;
}

h3 {
  padding: 0;
  margin: 0;
}

h4 {
  padding: 0;
  margin: 0;
}

h5 {
  padding: 0;
  margin: 0;
}

h6 {
  padding: 0;
  margin: 0;
}

hgroup {
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-position: outside;
  list-style-image: none;
}

ol {
  padding: 0;
  margin: 0;
  list-style-position: outside;
  list-style-image: none;
}

dl {
  padding: 0;
  margin: 0;
}

dd {
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

figure {
  padding: 0;
  margin: 0;
}

pre {
  margin: 0;
  font-size: 0.85rem;
  line-height: 1.42;
  background: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  overflow: auto;
  word-wrap: normal;
}

fieldset {
  padding: 0;
  margin: 0;
}

blockquote {
  padding: 0;
  margin: 0;
}

form {
  padding: 0;
  margin: 0;
}

noscript {
  padding: 0;
  margin: 0;
}

iframe {
  padding: 0;
  margin: 0;
}

hr {
  padding: 0;
  margin: 0 0 calc(1.45rem - 1px);
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

li {
  margin-bottom: calc(1.45rem / 2);
}

ol li {
  padding-left: 0;
}

ul li {
  padding-left: 0;
}
.slick-active a {
    z-index: 2;
}
`;

export const LayoutWrapper = styled.div`
  padding-top: 80px;
`;

export const PageWrapper = styled.div`
  transition: 0.8s ease-out;
  &.loading {
    opacity: 0;
  }
`;
