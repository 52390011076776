import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";
import iconClose from '../../images/icon-close.svg'
import { StaticImage } from "gatsby-plugin-image";

const BannerWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .75s ease-out;
  z-index: 888;
  &.active {
    bottom: 0;
    visibility: visible;
    opacity: 1;
  }
`;

const Banner = styled.div`
  max-width: 646px;
  padding: 0 24px 54px 24px;
  text-align: center;
  font-family: Optima;
  font-size: 19px;
  color: #000;
  font-weight: 400;
  line-height: 1.25;
  background: #ffffff;
  position: relative;
  z-index: 2;
  h3 {
    margin: 0 0 25px 0;
    font-size: 38px;
    color: #9EA69C;
    font-weight: 400;
    letter-spacing: 1.9px;
    text-transform: uppercase;
  }
  p {
    margin: 0 0 25px 0;
  }
  a {
    display: inline-block;
    vertical-align: top;
    padding: 6px 40px 4px 40px;
    text-decoration: none;
    font-family: Optima;
    font-size: 17px;
    color: #000;
    letter-spacing: 2.72px;
    text-transform: uppercase;
    border-radius: 5px;
    background: #9EA69C;
    transition: background .3s ease-out;
    &:hover {
      background: #88a585;
    }
  }
  ${mediaMax.phoneXL`
    padding-bottom: 24px;
    text-align: left;
    font-size: 15px;
    h3 {
      margin: 0 0 17px 0;
      font-size: 24px;
      letter-spacing: 1.2px;
    }
    p {
      margin: 0 0 17px 0;
    }
    a {
      padding: 6px 20px 4px 20px;
      font-size: 16px;
      letter-spacing: 2.56px;
    }
  `}
`;

const ButtonClose = styled.div`
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 2;
  cursor: pointer;
  ${mediaMax.phoneXL`
    top: 18px;
    right: 16px;
  `}
`;

const ImgWrapper = styled.div`
  margin: 0 -24px 30px -24px;
  ${mediaMax.phoneXL`
    margin-bottom: 15px;
  `}
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right:0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
`;


const BannerModal = (location) => {
  const bannerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false)
  const pathNameWithoutSlashes = location.location.pathname.replace(/\//g, '')

  useEffect(() => {
    const firstLoadPopup = localStorage.getItem("firstLoadPopup");
    if (!firstLoadPopup && pathNameWithoutSlashes !== 'contact') {
      const handleScroll = () => {
        const scrollY = window.scrollY;
        const triggerOffset = 200;
        if (scrollY > triggerOffset) {
          setTimeout(() => setIsOpen(true), 100)
          setTimeout(() => bannerRef?.current?.classList.add('active'), 600)
          window.removeEventListener('scroll', handleScroll);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [])

  const closeModal = () => {
    bannerRef?.current?.classList.remove('active')
    setTimeout(() => setIsOpen(false), 750)
    localStorage.setItem("firstLoadPopup", "true");
  }

  useEffect(() => {
    localStorage.removeItem("firstLoadPopup");
  }, []);

  return (
    <>
      {isOpen && (
        <BannerWrapper ref={bannerRef}>
          <Banner>
            <ButtonClose onClick={closeModal}>
              <img src={iconClose} alt="icon close X" />
            </ButtonClose>
            <ImgWrapper>
              <StaticImage src="../../../static/popup_image.jpg" alt="" />
            </ImgWrapper>
            <h3>now move-in ready</h3>
            <p>Parkhouse Residences is over 80% sold.<br/> Discover our remaining homes today.</p>
            <a href={"/contact"}>SCHEDULE A TOUR</a>
          </Banner>
          <Overlay onClick={closeModal} />
        </BannerWrapper>
      )}
    </>
  )
}

export default BannerModal