import React, { useEffect } from "react";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const timeout = 0;
const getTransitionStyles = {
  entering: {
    opacity: 0,
    position: "absolute",
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
};

const Transition = ({ children, location, isFirstLoad }) => {
  useEffect(() => {
    Promise.all(
      Array.from(document.images).map((img) => {
        if (img.complete) return Promise.resolve(img.naturalHeight !== 0);
        return new Promise((resolve) => {
          img.addEventListener("load", () => resolve(true));
          img.addEventListener("error", () => resolve(false));
        });
      })
    ).then((results) => {
      if (results.every((res) => res)) {
        setTimeout(() => {
          ScrollTrigger.getAll().forEach((ST) => ST.refresh());
        }, 600);
        console.log("all images loaded successfully");
      } else {
        console.log("some images failed to load, all finished loading");
      }
    });
    ScrollTrigger.getAll().forEach((ST) => {
      ST.refresh();
    });
    // This in case a scroll animation is active while the route is updated
    gsap.killTweensOf(window);
  });
  return (
    <TransitionGroup>
      <ReactTransition
        isfirstload={isFirstLoad}
        key={location && location.pathname}
        timeout={{
          enter: 100,
          exit: 100,
        }}
      >
        {(status) => (
          <div
            style={{
              ...getTransitionStyles[status],
            }}
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};

export default Transition;
